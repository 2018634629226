<template>
  <div class="w-100 py-15 headerBG">
    <div class="container text-white d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
        <div>
            <h2 class="text-center text-md-left">
              {{ $t("Buy.search.HaveQuestions") }}
            </h2>
            <h6 class="text-center text-md-left subText">
              {{ $t("Buy.search.HereForYou") }}
            </h6>
        </div>
            <button
              class="py-2 px-7 rounded-pill btn btn-primary text-break askNowBtn"
              @click="openModal()"
            >
              {{ $t("global.label.askNow") }}
            </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["setCustomerServiceModal"]),
    openModal() {
      this.setCustomerServiceModal(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.headerBG {
  background-image: url("~@/assets/img/salesRepresentative_bg.jpg");
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.subText {
    @media screen and (min-width:960px) {
        width: 75%;
    }
}
.askNowBtn {
    width:100%;
    @media screen and (min-width:960px) {
        width:200px;
    }
}
</style>
